import jQuery from 'jquery';
import Rails from '@rails/ujs';
import Bugsnag from '@bugsnag/js';

Bugsnag.start({
  apiKey: '9b65197d430db0d10363a0f1f4893fae',
  releaseStage: process.env.NODE_ENV,
  endpoints: {
    notify: 'https://bugsnag.matzlika.co.jp/notify',
    sessions: 'https://bugsnag.matzlika.co.jp/sessions',
  }
});

window.$ = jQuery;
window.jQuery = jQuery;
window.Rails = Rails;

Rails.start();

import 'jquery-ui';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/accordion';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/widgets/checkboxradio';
import 'jquery-ui/ui/widgets/controlgroup';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/menu';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/progressbar';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/selectable';
import 'jquery-ui/ui/widgets/selectmenu';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/spinner';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/ui/effects/effect-blind';
import 'jquery-ui/ui/effects/effect-bounce';
import 'jquery-ui/ui/effects/effect-clip';
import 'jquery-ui/ui/effects/effect-drop';
import 'jquery-ui/ui/effects/effect-explode';
import 'jquery-ui/ui/effects/effect-fade';
import 'jquery-ui/ui/effects/effect-fold';
import 'jquery-ui/ui/effects/effect-highlight';
import 'jquery-ui/ui/effects/effect-puff';
import 'jquery-ui/ui/effects/effect-pulsate';
import 'jquery-ui/ui/effects/effect-scale';
import 'jquery-ui/ui/effects/effect-shake';
import 'jquery-ui/ui/effects/effect-size';
import 'jquery-ui/ui/effects/effect-slide';
import 'jquery-ui/ui/effects/effect-transfer';
import 'jquery-ui/ui/i18n/datepicker-ja';

import 'jquery-ujs';
import 'bootstrap';

import './scripts';
import './app';
