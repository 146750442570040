$(function () {
    $('.search-form').on('submit', function (e) {
        e.preventDefault();
        return false;
    });
    function getQuery() {
      return location.search.substring(1).split('&').map((p) => p.split('='))
        .reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {});
    }
    function bindSearch() {
        var query_;
        var xhr = null;
        $('.search-input').on('keyup input', _.debounce(function () {
            var $el = $(this);
            var q = $el.val();
            if (q === query_) {
                return;
            }
            query_ = q;
            var data = getQuery();
            delete data.page;
            data.q = q;
            data.type = $el.data('type');

            if (xhr) { xhr.abort(); }
            xhr = new window.XMLHttpRequest();

            $.ajax({
                url: $el.data('url'),
                data: data,
                xhr: function () {
                  return xhr;
                }
            }).then(function (_res) {
                xhr = null;
            }).catch(function (err) {
                console.error(err);
            });
        }, 500));
    }
    window.bindSearch = bindSearch;
    bindSearch();
});
