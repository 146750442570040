export function number_with_delimiter(number, delimiter, separator) {
    try {
        var delimiter = delimiter || ",";
        var separator = separator || ".";

        var parts = number.toString().split('.');
        parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter);
        return parts.join(separator);
    } catch(e) {
        return number
    }
}

export function decimal_round(num, digit = 2) {
  if (!num) return 0;

  var precision = 0;
  while(precision < digit) {
    if (num === Math.floor(num)) break;
    precision += 1;
  }

  var pow = Math.pow(10, precision)

  return Math.round(num * pow) / pow;
}
