import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

const MonthSelection = React.lazy(() => import('./../components/projects/MonthSelection'));
const EventScheduleForm = React.lazy(() => import('./../views/projects/EventScheduleForm'));
const SelectMember = React.lazy(() => import('./../views/projects/SelectMember'));

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.month-selection').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<div className="monthlySelect">Loading...</div>}>
        <MonthSelection {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );

  document.querySelectorAll('.event-schedule-form').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<div>Loading...</div>}>
        <EventScheduleForm {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );

  document.querySelectorAll('.select-member').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<div>Loading...</div>}>
        <SelectMember {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );
});
