import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditIcon = props => {
  const style = {
    position: 'absolute',
    top: '8px',
  };
  switch (props.position) {
    case 'left':
      style.left = '8px';
      break;
    case 'none':
      return (null);
    default:
      style.right = '8px';
      break;
  }

  return (
    <FontAwesomeIcon icon={['far', 'edit']} style={style} />
  );
};

export default EditIcon;
