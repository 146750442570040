import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Loading from './../components/Loading';

const DauChart = React.lazy(() => import('./../views/dashboard/DauChart'));
const Reminder = React.lazy(() => import('./../views/dashboard/Reminder/Reminder'));

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.dau-chart').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<Loading />}>
        <DauChart {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );

  document.querySelectorAll('.reminder').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<Loading />}>
        <Reminder {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );
});
