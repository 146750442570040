import { number_with_delimiter, decimal_round } from './utils';

$(function () {
    // drag and drop file upload
    (() => {
      var $dropArea = $('.file-dropdown-target');
      $dropArea.on('dragover', function(e) {
        e.preventDefault();
        $(e.currentTarget).addClass('dragover');
      });
      $dropArea.on('dragleave', function(e) {
        e.preventDefault();
        $(e.currentTarget).removeClass('dragover');
      });
      $dropArea.on('drop', function(e) {
        e.preventDefault();
        var $this = $(e.currentTarget);
        $this.removeClass('dragover');
        var $target = $($this.data('target'));
        $target[0].files = e.originalEvent.dataTransfer.files;
        $target.trigger('change');
      });

      $(document).on('change',  'input.fileupload', function (e) {
          var $el = $(e.currentTarget);
          var $loading = $('<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>');
          $('#' + $el.data('container')).append($loading);

          var formData = new FormData();

          [...e.target.files].forEach(function (file) {
            formData.append($el.attr('name'), file);
          });

          var orgData = $el.data('form-data');
          if (orgData) {
            Object.keys(orgData).forEach(function (key) {
              formData.append(key, orgData[key]);
            });
          }

          Rails.ajax({
            type: 'POST',
            url: $el.data('url'),
            data: formData,
            processData: false,
            contentType: false,
            error: function () {
              alert('ファイルアップロードに失敗しました。');
            },
            complete: function () {
              $loading.remove();
            },
          });
      });
    })();

    $('.add.daytime').on('ajax:success', function (data) {
        console.log('ajax:success', arguments);
        var $el = $('.project-event-item').last();
        $el.find('input').each(function (i, el) {

        })
    });
    $('.add-cloneable').on('click', function () {
        var $target = $('#' + $(this).data('for'));
        var $row = $($target.find('.cloneable-row').eq(0));
        var $clone = $row.clone();
        $row.closest('tbody').append($clone);
        $clone.find('input').val('');
        $clone.find('select').val('');
        $clone.find('.total-price').text('');
        $clone.find('.position-input').val($target.find('.cloneable-row').length);
        $clone.find('.price-item-without-tax-off').val(0);
        $clone.find('.price-item-without-tax-on').val(1);
        var $parent = $clone.find('select.parent');
        $parent.each(function (i, el) {
            var $el = $(el);
            var parentId = $el.attr('id');
            var newId = 'selection-pair-' + new Date().getTime();
            var childSelector = 'select[data-parent="' + parentId + '"]';
            $clone.find(childSelector).attr('data-parent', newId);
            $el.attr('id', newId);
        });
        addRowByTab();
    });
    $(document).on('click', '.delete-item', function () {
      const $this = $(this);
      const url = $this.data('path');
      const complete = () => {
        $this.closest('tr').remove();
        calcPrices();
      };
      if (url) {
        $.ajax({ type: 'DELETE', url, complete })
      } else {
        complete();
      }
    });
    $(document).on('change', '.price-item-without-tax-on', calcPrices);
    $(".sortable").each(function (i, elem) {
      var $elem = $(elem);
      var options = {
        update : function(ev, ui) {
          $(this).find('.position-input').each(function (i, el) {
              $(el).val(i);
          })
        },
        cancel: ''
      };
      if ($elem.data('handle')) {
        options.handle = $elem.data('handle');
      }
      $elem.sortable(options);
    });
    // $(".sortable").disableSelection();

    $('.sortable.mail-send-to').on( "sortupdate", function(event, ui) {
        var clients = $(this).find('li').toArray().map(function (el) {
            return $(el).data('display-name').replace(/(\\r)?\\n/g, "\n");;
        });
        window.setClients(clients);
    });

    $(document).on('click', '.delete-row-button', function () {
        $(this).closest('tr').remove();
    });
    $(document).on('click', '.delete-target', function () {
        $($(this).data('target')).remove();
    });
    window.calcPrices = calcPrices;
    $(document).on('change', '.select-with-other', function () {
        toggleOtherInput($(this));
    });
    function calcPrices() {
        var taxRate = parseFloat($('.tax-rate').val()) / 100;
        var withoutTax = 0;
        var taxSum = 0;
        var total = 0;
        $('.price-row').each(function (i, el) {
            var $container = $(el);
            var price = fetchNumber($container, '.price-item-price') * fetchNumber($container, '.price-item-amount');
            if (isNaN(price)) {
                return;
            }
            withoutTax += price;
            $container.find('.total-price').text(number_with_delimiter(decimal_round(price, 0)) + '円');
            if (!$container.find('.price-item-without-tax').is(':checked')) {
                var tax = price * taxRate;
                taxSum += tax;
                price += tax;
            }
            total += price;
        });
        $('#total-without-tax').text(number_with_delimiter(decimal_round(withoutTax, 0)) + '円');
        $('#total-tax').text(number_with_delimiter(decimal_round(taxSum, 0)) + '円');
        $('#total-price').text(number_with_delimiter(decimal_round(total, 0)) + '円');
    }
    $(document).on('change', '.price-row input, .tax-rate', calcPrices);
    setTimeout(calcPrices, 0);
    $(document).on('change', '.zip-code-input', function () {
        var $this = $(this);
        var $container = $this.closest('.address-context');
        $.getJSON('/js/zip-code/' + $this.val(), function (data) {
            $container.find('.address-input').val(data.address).focus();
        });
    });
    $(document).on('click', '.auto-zip', function (e) {
        var $this = $(e.currentTarget);
        var $container = $this.closest('.address-context') || $('body');
        if ($container.length <= 0) $container = $(document);
        $.getJSON('/js/zip-code/' + $($this.data('from')).val(), function (data) {
            $container.find($this.data('to')).val(data.address).focus();
        });
    });
    $(document).on('ajax:before', '.ajax-progress', function () {
        $('#modal-container').html('<div class="modal_wrap">\n' +
            '<input id="progress-modal" type="checkbox" checked>\n' +
            '<div class="modal_overlay">\n' +
            '<div class="modal_content text-center" style="width: 200px">\n' +
            '<p>送信中...</p>' +
            '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>' +
            '</div>\n' +
            '</div>\n' +
            '</div>')
    }).on('ajax:complete', '.ajax-progress', function () {
        $('#modal-container').find('.modal_content').html(
            '<i class="fa fa-check text-success"></i> 完了' +
            '<div class="modalBtn">\n' +
            '<label class="button white" for="progress-modal">完了</label>\n' +
            '</div>');
    });

    $('.cancel-button').on('click', function (e) {
        e.preventDefault();
        history.back();
        return false;
    });

    $('.show-when-selected').each(function (i, el) {
      var $el = $(el);
      if ($el.is(':radio')) {
        var name = $el.attr('name');
        var $elems = $('[name="' + name + '"]:radio');
        $elems.on('change', function (e) {
          $elems.each(function (ii, elem) {
            var $elem = $(elem);
            if (!$elem.hasClass('show-when-selected')) return;
            var $target = $($elem.data('target'));
            if ($elem.prop('checked')) {
              $target.show();
            } else {
              $target.hide();
            }
          });
        }).trigger('change');
      } else {
        $el.on('change', function (e) {
            var $this = $(e.currentTarget);
            var $target = $($this.data('target'));
            if ($this.prop('checked')) {
              $target.show();
            } else {
              $target.hide();
            }
        }).trigger('change');
      }
    });

    $('.disabled-when-checked').on('change', function (e) {
        var $this = $(e.currentTarget);
        var $target = $($this.data('target'));
        $target.prop('disabled', $this.prop('checked'));
    }).trigger('change');

    $(document).on('change', '.change-submit', function (e) {
        var $this = $(e.currentTarget);
        const $parent = $this.parent();

        var form = document.createElement('form');
        form.style.display = 'none';
        form.action = $this.data('url');
        form.method = $this.data('method') || 'post';
        form.enctype = "multipart/form-data";

        form.appendChild(e.currentTarget);

        if (form.method !== 'get') {
          var q = document.createElement('input');
          q.name = 'authenticity_token';
          q.value = $("meta[name=csrf-token]").attr("content");
          form.appendChild(q);

          var q = document.createElement('input');
          q.name = 'utf8';
          q.value = '✓';
          form.appendChild(q);

          var q = document.createElement('input');
          q.name = '_method';
          q.value = $this.data('method') || 'post';
          form.appendChild(q);
        }

        document.body.appendChild(form);
        form.submit();

        $parent.append(e.currentTarget);
        $this.val('');
    });

    $(document).on('change', '.rails-change-submit', function (e) {
        var $this = $(e.currentTarget);
        if ($this.data('form')) {
          var $form = $('#' + $this.data('form'));
          var $input = $this.clone();
          $input.val($this.val());
          $input.css('display', 'none');
          $form.append($input);
          Rails.fire($form[0], 'submit');
          $input.remove();
        } else {
          var $form = ($this.attr('form')) ? $('#' + $this.attr('form')) : $($this.parents('form'));
          Rails.fire($form[0], 'submit');
        }
    });

    /**
     * チェックボックスのカウント
     */
    $(document).on('change', '.count-checkbox', function () {
      $('.count-checkbox-result').text($('.count-checkbox:checked').length);
    });

    function toggleOtherInput($selectWithOther) {
        var $other = $('#' + $selectWithOther.data('other'));
        var $selected = $selectWithOther.find(':selected');
        if (!$selected.hasClass('other')) {
            $other.val('');
        }
        $other.prop('disabled', !$selected.hasClass('other'));
        $other.focus();
    }

    function addRowByTab() {
        $('.price-row input[type="text"]').off('keydown');
        $('.price-row input[type="text"]:last').on('keydown', function (e) {
            if (e.keyCode === 9) {
                $('.add-cloneable').trigger('click');
            }
        });
    }

    function initDependentSelection() {
        $(document).on('change', 'select.parent', function () {
            updateChildSelection($(this));
            var childSelector = 'select[data-parent="' + $(this).attr('id') + '"]';
            $(childSelector).val('');
        });
        $('select.parent').each(function (i, el) {
            updateChildSelection($(el));
        })
    }

    function updateChildSelection($selected) {
        var selected = $selected.val();
        var parentId = $selected.attr('id');
        var childSelector = 'select[data-parent="' + parentId + '"]';
        $(childSelector + ' option').css('display', 'none');
        $(childSelector + ' option[value=""]').css('display', 'block');
        $(childSelector + ' option[data-val="' + selected + '"]')
            .css('display', 'block');
    }

    function fetchNumber($container, selector) {
        return parseFloat($container.find(selector).val());
    }

    function scrollToElement() {
        var hash = location.hash.substr(1);
        var element = document.getElementById('anchor-' + hash);
        if (element) {
            var rect = element.getBoundingClientRect();
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: rect.y - 70
                }, 200);
                console.log('scrollToElement', rect.y - 70);
            }, 33);
        }
    }

    function setFilterLinks() {
        $(document).on('click', '.filter-link', function() {
            var $el = $(this);
            var search = location.search.substr(1);
            var query = getQuery(search);
            delete query.page;
            var dataQuery = getQuery($el.data('param'));
            _.assign(query, dataQuery);

            location.search = buildQueryUrl(query);
        });
    }

    function buildQueryUrl(query) {
        var pairs = [];
        _.each(_.keys(query), function(k) {
            pairs.push(k + '=' + query[k]);
        });
        return pairs.join('&');
    }

    function getQuery(search) {
        var query = {};
        if (!search) {
          return query;
        }
        _.each(search.split('&'), function(pair) {
            if (pair.indexOf('=') > -1) {
                var pairArr = pair.split('=');
                var key = decodeURIComponent(pairArr[0]);
                var value = decodeURIComponent(pairArr[1]);
                query[key] = value;
            }
        });
        return query;
    }

    function fadeOutAlert() {
        setTimeout(function () {
            $('.alert').fadeOut(800, function () {
                $(this).remove();
            })
        }, 1000);
    }

    function init() {
        addRowByTab();
        initDependentSelection();
        $('.select-with-other').each(function (i, el) {
            toggleOtherInput($(el));
        });
        $(document).on('click', '.project-list-item', function () {
            $(this).toggleClass('open');
        });
        $(document).on('click', '.select-deselect-all', function () {
            var selector = $(this).data('selector');
            $(selector).prop('checked', !$(selector).prop('checked'));
        });
        $(document).on('click', '.remove-send-to', function () {
            var $element = $(this).closest('.client-send-to');
            $element.remove();
            var display_name = $element.data('display-name').replace(/\\r/g, '\r').replace(/\\n/g, '\n');
            var index = clients.indexOf(display_name);
            console.log({index, display_name});
            if (index > -1) {
                clients.splice(index, 1);
                window.setClients(clients);
            }
        });
        $("form input").on("keypress", function (e) {
            if (e.keyCode == 13) {
                return false;
            }
        });
        setFilterLinks();
        //scrollToElement();
        fadeOutAlert();
    }

    init();

    window.callbacks = {};
});
