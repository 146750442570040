import React from 'react';
import EditIcon from './EditIcon';

const LoadingForm = () => (
  <div className="confirmCnt position-relative">
    Loading...
    <EditIcon />
  </div>
);

export default LoadingForm;
