import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Loading from './../components/Loading';

const EmailEditor = React.lazy(() => import('./../views/email/EmailEditor'));

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.email-editor').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<Loading />}>
        <EmailEditor {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );
});
