import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

// ロード中がロード中だと無意味なので。。。
import LoadingForm from './../components/form/LoadingForm';

const EditableInput = React.lazy(() => import('./../components/form/EditableInput'));
const EditableTextArea = React.lazy(() => import('./../components/form/EditableTextArea'));
const EditableSelect = React.lazy(() => import('./../components/form/EditableSelect'));
const DatePicker = React.lazy(() => import('./../components/form/DatePicker'));
const DateTimePicker = React.lazy(() => import('./../components/form/DateTimePicker'));

document.addEventListener('DOMContentLoaded', () => {
  // TODO: 定義方法は同じなので動的コンポーネントで定義
  document.querySelectorAll('.editable-input').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<LoadingForm />}>
        <EditableInput {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );

  document.querySelectorAll('.editable-textarea').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<LoadingForm />}>
        <EditableTextArea {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );

  document.querySelectorAll('.editable-datepicker').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<LoadingForm />}>
        <DatePicker {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );

  document.querySelectorAll('.editable-datetimepicker').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<LoadingForm />}>
        <DateTimePicker {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );

  document.querySelectorAll('.editable-select').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<LoadingForm />}>
        <EditableSelect {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );
});
