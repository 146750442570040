import React, { memo } from 'react';
import styles from './index.module.scss';

const Loading = memo(() => {
  return (
    <div className={styles.loading}>
      Loading...
    </div>
  );
});

export default Loading;
