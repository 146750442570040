import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Loading from './../components/Loading';

const Calendar = React.lazy(() => import('./../views/calendar/Calendar'));

document.addEventListener('DOMContentLoaded', () => {
  // NOTE: サイズをrefsで計算したりする都合上、最初に表示されるものはlazyだと狂う
  document.querySelectorAll('#calendar').forEach(node => {
    createRoot(node).render(
      <Suspense fallback={<Loading />}>
        <Calendar {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  });
});
