$(() => {
  'use strict';

  // プロジェクト一覧でフリーワード検索した場合、URLも書き換え
  $(document).on('keyup input', '.js__project-search', e => {
    const params = [...new URLSearchParams(location.search).entries()].reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {});
    params.q = encodeURIComponent(e.currentTarget.value);
    const search = Object.entries(params).map(e => `${e[0]}=${e[1]}`).join('&');
    history.replaceState(null, null, `${location.pathname}?${search}${location.hash}`);
  });
});
