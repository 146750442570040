import { number_with_delimiter } from './utils';

$(function () {
    function calcPrice(plan) {
        var price = gon.plans[plan];
        if (plan === 'lion' || plan === 'mammoth') {
            price += $('.plan-extra-accounts').val() * 500;
        }
        $('.plan-price').html(number_with_delimiter(price) + '円');
    }

    function setPlanClass($table, plan) {
        _.each($table[0].classList, function (className) {
            if (className !== 'plan-table') {
                $table.removeClass(className);
            }
        });
        $table.addClass('plan-' + plan);
        return plan;
    }

    $('.plan-select').on('change', function () {
        var $this = $(this);
        var $table = $this.closest('.plan-table');
        var plan = $this.val();
        setPlanClass($table, plan);
        setPlanClass($this.closest('form'), plan);

        calcPrice(plan);
    });


    $('.plan-extra-accounts').on('change', function () {
        calcPrice($('.plan-select').val());
    });
});
