import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Loading from './../components/Loading';

const PaymentMethods = React.lazy(() => import('./../components/users/PaymentMethods'));

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.payment-methods').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<Loading />}>
        <PaymentMethods {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );
});
