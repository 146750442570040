import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import LoadingForm from './../components/form/LoadingForm';

const PriceList = React.lazy(() => import('./../views/form/PriceList'));

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.editable-price-list').forEach(node =>
    createRoot(node).render(
      <Suspense fallback={<LoadingForm />}>
        <PriceList {...Object.assign({}, node.dataset)} />
      </Suspense>
    )
  );
});
